/*
  # Reset
  # Outline
*/


// # Reset
.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  //font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
}

// # Outline
.no-outline {
  &:focus {
    outline: none;
  }
}

.btn-c-primary {
	color: $primary;
	border: rem(1) solid $primary;
	padding: rem(7) rem(28);
  &:hover{
    color: $primary;
    outline: rem(1) solid $primary;
  }
}

.btn-c-primary--alt {
  &:hover {
    background-color: $tertiary;
    border-color: $tertiary;
    color: #fff;
    outline: none;
  }
}

.btn-c-white {
	color: #fff;
	border: rem(1) solid #fff;
	padding: rem(7) rem(28);
  &:hover {
    color: #fff;
  }
}

.btn-c-secondary {
  color: #FFF;
  border: rem(1) solid $primary;
  padding: rem(7) rem(28);
  background: $primary;
  &:hover{
    background-color: $tertiary;
    border-color: $tertiary;
    color: #fff;
    outline: none;
  }
}
