.text-tertiary { color: $tertiary }
.text-tertiary--inherit {
	a {
		color: $tertiary;
		font-weight: 500;
	}
}

.link-white-inherit {
	a {
		color: #fff;
	}
}

@include media-breakpoint-up(lg) {
	.text-lg-white { color: #fff }
}
