#accordionOffers {
  .btn-heading {
    font-weight: bold;
    position: relative;
    color: $tertiary;
    border-bottom: 1px solid $tertiary;
    padding-bottom: 1rem;
    &:hover {
      text-decoration: none;
      color: $primary;
    }
    &:before {
      position: absolute;
      content: "+";
      right: 0.75rem;
      font-size: 1.6rem;
    }
    &[aria-expanded="true"] {
      &:before {
        content: "-";
      }
    }
  }
}