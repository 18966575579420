/*
  # Background image with object-fit
*/

// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@include media-breakpoint-up(md) {
	.bg-md-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}


// # Opacities
@each $opacity in $opacities {
  .opacity-#{$opacity} {
		opacity: #{$opacity/100};
  }
}