footer {
	background-color: $blue;
	padding-top: rem(34);
	padding-bottom: rem(38);
	z-index: 2;
	a {
		color: #fff;
	}
	address {
		line-height: (24/14);
	}
	@include media-breakpoint-up(md) {
		padding-top: rem(84);
		padding-bottom: rem(84);
		.footer__li {
			line-height: (24/14);
		}
	}
	@include media-breakpoint-up(lg) {
		padding-left: rem(68);
		padding-right: rem(68);
	}
}



.list-footer-legal {
	line-height: rem(30);
}

.list-footer-left {
	@include media-breakpoint-down(md) {
		li {
			&:not(:last-child) {
				margin-bottom: rem(6)
			}
		}
	}
}

.list-footer-categories {
	li {
		&:not(:last-child) {
			margin-bottom: rem(23);
		}
	}
	a {
		&:hover {
			opacity: .5;
		}
	}
}