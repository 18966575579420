header {
  background-color: transparent;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

.header__top {
	padding: 0 rem(20);
	.logo-sukia-50 {
		height: rem(32);
		position: relative;
		top: -6px;
		img {
			max-height: 100%;
			width: auto;
		}
	}
	@include media-breakpoint-down(md) {
		height: rem(76);
		.logo-sukia-50 {
			height: rem(18);
		}
	}
	@include media-breakpoint-up(lg) {
		padding: rem(68) rem(82) 0;
	}
}

.header__content {
	background-color: #12263A;
	bottom: 0;
	left: 0;
	opacity: 0;
	padding: rem(74) rem(20) rem(88);
	position: absolute;
	right: 0;
	top: rem(76);
	visibility: hidden;
	@include media-breakpoint-up(lg) {
		top: rem(100)
	}
}

.btn-burguer {
	height: rem(16);
	position: relative;
	width: rem(30);
	span {
		background-color: #fff;
		content: "";
		display: block;
		height: rem(2);
		left: 0;
		position: absolute;
		right: 0;
    	transition-duration: .5s;
		&:first-child {
			top: 0;
		}
		&:nth-child(2) {
			top: rem(7);
		}
		&:last-of-type {
			bottom: 0;
		}
	}
	@include media-breakpoint-up(xl) {
		height: rem(24);
		width: rem(40);
		span {
			&:nth-child(2) {	
				top: rem(11);
			}
		}
	}
}

.menu--open {
	overflow: hidden;
	header {
		background-color: #12263A;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 4;
	}
	.header__content {
		overflow: scroll;
		opacity: 1;
		visibility: visible;
	}
	.btn-burguer {
		span {
			&:first-child {
				transform: translate(0, rem(7)) rotate(-45deg)
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:last-of-type {
				transform: translate(0, rem(-7)) rotate(45deg)
			}			
		}
		&:hover {
			span {
				background-color: $orange;
			}
		}
	}
  .logo {
    &__name { fill: #fff }
    &__imago { fill: #da6a2c }
  }
	@include media-breakpoint-up(xl) {
		.btn-burguer {
			span {
				&:first-child {
					transform: translate(0, rem(11)) rotate(-45deg)
				}
				&:last-of-type {
					transform: translate(0, rem(-11)) rotate(45deg)
				}			
			}
		}
	}
}




.logo {
	fill: #fff;
	height: rem(18);
	width: rem(114);
	@include media-breakpoint-up(lg) {
		height: rem(32);
		width: rem(200);
	}
}

.list-nav {
	margin-bottom: rem(114);
	li {
		&:not(:last-child) {
			margin-bottom: rem(41);
		}
	}
	a {
		border-bottom: 1px solid transparent;
		&:hover {
			border-color: #fff;
		}
	}
}

.list-lang {
	li {
		&:not(:last-child) {
			border-right: .1rem solid #fff;
		}
		> * {
			padding: 0 rem(8.5);
		}
	}
}

.list-social {
	li {
		&:not(:last-child) {
			margin-right: rem(42);
		}
	}
	a {
		fill: currentColor;
		opacity: .5;
		display: flex;
		&:hover {
			opacity: .5;
		}
	}
	@include media-breakpoint-up(xl) {
		a {
			opacity: 1;
		}
	}
}

.facebook {
	height: rem(17);
	width: rem(9);
	@include media-breakpoint-up(xl) {
		height: rem(26);
		width: rem(14);
	}
}
.instagram {
	height: rem(17);
	width: rem(17);
	@include media-breakpoint-up(xl) {
		height: rem(26);
		width: rem(26);
	}	
}
.youtube {
	height: rem(14);
	width: rem(19);
	@include media-breakpoint-up(xl) {
		height: rem(21);
		width: rem(29);
	}	
}
.twitter {
	height: rem(12);
	width: rem(15);
	@include media-breakpoint-up(xl) {
		height: rem(19);
		width: rem(24);
	}	
}
.linkedin {
	height: rem(12);
	width: rem(12);
	@include media-breakpoint-up(xl) {
		height: rem(18);
		width: rem(18);
	}	
}

