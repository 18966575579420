.link-std {
	color: $blue;
	&:hover {
		color: $blue;
	}
}

.link-nd {
	&:hover {
		text-decoration: none;
	}
}

.link-un {
	text-decoration: underline;
}

.link-01 {
	overflow: hidden;
	position: relative;
	&:before, &:after {
		content: "";
	}
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($blue, .45);
		transition: background-color .6s ease-in-out;
		z-index: 1;
	}	
	&:after {
		padding-bottom: ( (9/16) * 100%)
	}
	img {
		transition: transform .6s ease-in-out;
	}
	&__content {
		padding: rem(16) rem(22);
		z-index: 1;
	}
	&:hover {
		&:before {
			background-color: rgba($blue, 0);
		}	
		img {
			transform: scale(1.2)
		}
	}
	@include media-breakpoint-up(md) {
		&:after {
			padding-bottom: ( (418/928) * 100%)
		}
	}
}
