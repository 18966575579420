@font-face {
  font-family: 'MangoGrotesque';
  src: url('../fonts/MangoGrotesque-Bold.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/MangoGrotesque-Bold.otf') format('opentype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'MangoGrotesque';
  src: url('../fonts/MangoGrotesque-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/MangoGrotesque-Regular.otf') format('opentype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'MangoGrotesqueSemBd';
  src: url('../fonts/MangoGrotesque-SemiBold.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/MangoGrotesque-SemiBold.otf') format('opentype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.ff-mango {
  font-family: "MangoGrotesque", sans-serif;
}

.ff-mango-sm {
  font-family: "MangoGrotesqueSemBd";
}