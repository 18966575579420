.swiper-interested {
	overflow: visible !important;
}

.overflow-hidden { overflow: hidden }

.swiper-pagination {
	position: absolute;
	left: 0;
	right: 0;
	bottom: rem(24);
	z-index: 1;
	span {
		&:not(:last-child) {
			margin-right: rem(9);
		}
	}
	@include media-breakpoint-up(lg) {
		bottom: rem(60);
	}
}

.swiper-pagination-bullet {
	display: inline-block;
	background-color: #fff;
	border-radius: 50%;
	height: rem(9);
	width: rem(9);
	opacity: .48;
}

.swiper-pagination-bullet-active {
	opacity: 1;
}