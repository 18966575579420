.modal-xl {
    @include media-breakpoint-up(lg) {
        max-width: 800px;
    }
    @include media-breakpoint-up(xl) {
        max-width: 1140px;
    }
}

#modal_reservation {
   .modal-content {
     background-color: transparent;
     .modal-header, .modal-body {
       padding: 0;
       max-width: 750px;
       width: 100%;
       margin-left: auto;
       margin-right: auto;
     }
     .modal-header {
       .close {
         font-size: 2rem;
         margin-bottom: .2rem;
         color: rgb(255,255,255);
       }
     }
     .modal-body-header {
       margin-bottom: 2rem;
     }
   }
}