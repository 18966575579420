.about-break {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($tertiary, .49);
		z-index: 1;
	}
	&__content {
		position: relative;
		z-index: 1;
	}
}