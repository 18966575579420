.view-404 {
  min-height: 100vh;
  @include media-breakpoint-up(sm) {
    &:before {
      color: rgba($blue, .1);
      content: "404";
      font-size: rem(200);
      font-weight: 700;
      letter-spacing: 0.1em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
    }
  }
  @include media-breakpoint-up(md) {
    &:before {
      font-size: rem(300);
    }
  }
  @include media-breakpoint-up(lg) {
    &:before {
      font-size: rem(400);
    }
  }
}