.promo-main-links {
	a {
		border-bottom: rem(1) solid transparent;
		padding: 0 rem(5);
		&.active, &:hover {
			border-bottom-color: #12263A;
		}
	}
	@include media-breakpoint-up(xl) {
		&:after {
			background-color: #12263A;
			content: "";
			width: rem(1);
			height: rem(28);
		}
		a {
			padding: rem(6);
			margin-right: rem(64)
		}
	}
}

.promo-filter {
	padding: 0 rem(24);
	position: relative;
	@include media-breakpoint-up(xl) {
		&:hover {
			.collapse-filter {
				display: block;
			}
			svg {
				transform: rotate(180deg)
			}
		}
	}
}

.promo-filter__btn {
	color: #fff;
	padding: rem(10) 0;
	&:not(.collapsed), &:hover {
		span { color: $orange }
		svg {
			transform: rotate(180deg)
		}
	}
	@include media-breakpoint-up(sm) {
		padding: rem(15) 0;
	}
	@include media-breakpoint-up(xl) {
		color: $blue;
		padding: 0
	}
}

.list-product {
	li {
		&:not(:last-child) {
			margin-bottom: rem(25)
		}
	}
	@include media-breakpoint-up(md) {
		margin-left: rem(-11);
		margin-right: rem(-11);
		li {
			padding-left: rem(11);
			padding-right: rem(11);
			&:not(:last-child) {
				margin-bottom: rem(22)
			}
		}
	}
}

.productlist {
  color: #fff;
  overflow: hidden;
  position: relative;
	&__title {
		color: #fff;
		margin-top: 0;
		margin-bottom: rem(7);
	}
	&__subtitle {
		color: #fff;
	}
  &:before, &:after {
    content: "";
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($blue, .45);
    transition: background-color .6s ease-in-out;
    z-index: 1;
  }	
  &:after {
    padding-bottom: ( (573/611) * 100%)
  }
  img {
    transition: transform .6s ease-in-out;
  }
  &__content {
    padding: rem(16) rem(22);
    z-index: 1;
  }
  &:hover {
    &:before {
      background-color: rgba($blue, 0);
    }	
    img {
      transform: scale(1.2)
    }
	}
}

.list-social--bottom {
	li {
		&:not(:last-child) {
			margin-right: rem(32);
		}
	}
	a {
		color: rgba($tertiary, .49);
		&:hover {
			color: rgba($tertiary, 1);
		}
	}
	@include media-breakpoint-up(md) {
		li {
			&:not(:last-child) {
				margin-right: rem(48);
			}
		}
	}
}

.promo-filters {
	@include media-breakpoint-down(lg) {
		background-color: #12263A;
		overflow: scroll;
		position: fixed;
		bottom: 0;
		height: 100%;
		left: 0;
		right: 0;
		width: 60%;
		min-width: rem(290);
		max-width: rem(360);
		padding: rem(10) 0;
		transform: translateX(-100%);
		transition: transform .2s ease;
		z-index: 3;
		&.active {
			transform: translate(0)
		}
	}
}

.list-promo-filter {
	li {
		cursor: pointer;
	}
	.selected {
		color: $primary
	}
}

.btn-filters {
	border: rem(1) solid #12263A;
	border-radius: 50%;
	bottom: rem(8);
	height: rem(48);
	position: fixed;
	right: rem(8);
	width: rem(48);
	z-index: 3;
}


.list-promo-filter {
	li {
		padding: rem(8) 0;
	}
}

.collapse-filter {
	background-color: #e5e5ed;
	padding: rem(15) rem(24);
	@include media-breakpoint-up(xl) {
		background-color: #fff;
		position: absolute;
		top: auto;
		left: 0;
		z-index: 2;
		height: auto !important;
		min-width: rem(262)
	}
}
