.container-promo-gallery {
  @media (min-width: 1718px) {
    max-width: 1658px;
  }
}

.promo-pagination {
  @media (min-width: 481px) {
    display: none;
  }
}

.promo-swiper-nav {
  @media (min-width: 481px) {
    display: flex !important;
  }
}

.swiper-button-disabled {
  opacity: 0.5;
}

.promo-quality {
  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: rem(60);
  }
}

.address-location {
  padding-left: rem(27 + 20);
  position: relative;
  &:before {
    background-color: $primary;
    border-radius: 50%;
    content: "";
    display: block;
    height: rem(27);
    left: 0;
    position: absolute;
    width: rem(27);
  }
}

.promo-address-info {
  @include media-breakpoint-up(lg) {
    position: relative;
    flex-grow: 1;
    &__in {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      padding: 0 rem(15);
    }
  }
}

.address-text {
  line-height: (22/18);
}

.map {
  position: relative;
  &:after {
    content: "";
    padding-bottom: (557/842) * 100%;
  }
}

.map-info {
  background-color: $blue;
  padding: rem(33) rem(36);
  &__title {
    line-height: (25/30);
  }
}

.accordion-project {
  svg {
    color: #12263a;
  }
  .btn-reset {
    &:not(.collapsed) {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}

.lh-promo-entradilla {
  line-height: (32/27);
}
