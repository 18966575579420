.ff-serif {
  font-family: $serif;
}

.text-cms {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.fs-10 {
  font-size: rem(10);
}
.fs-12 {
  font-size: rem(12);
}
.fs-14 {
  font-size: rem(14);
}
.fs-15 {
  font-size: rem(15);
}
.fs-16 {
  font-size: rem(16);
}
.fs-18 {
  font-size: rem(18);
}
.fs-20 {
  font-size: rem(20);
}
.fs-22 {
  font-size: rem(22);
}
.fs-24 {
  font-size: rem(24);
}
.fs-28 {
  font-size: rem(28);
}
.fs-30 {
  font-size: rem(30);
}
.fs-32 {
  font-size: rem(32);
}
.fs-40 {
  font-size: rem(40);
}
.fs-60 {
  font-size: rem(60);
}
.fs-64 {
  font-size: rem(64);
}
.fs-70 {
  font-size: rem(70);
}
.fs-100 {
  font-size: rem(100);
}

.lh-1 {
  line-height: 1;
}
.lh-1p16 {
  line-height: (35/30);
}
.lh-1p2 {
  line-height: (30/25);
}
.lh-1p3 {
  line-height: (40/30);
}

.ls-100 {
  letter-spacing: 0.1em;
}

.ls-0300 {
  letter-spacing: 0.03em;
}

@include media-breakpoint-up(sm) {
  // 576
  .fs-sm-16 {
    font-size: rem(16);
  }
}

@include media-breakpoint-up(md) {
  // 768
  .fw-md-300 {
    font-weight: 300;
  }
  .fw-md-400 {
    font-weight: 400;
  }
  .fw-md-700 {
    font-weight: 700;
  }

  .fs-md-15 {
    font-size: rem(15);
  }
  .fs-md-16 {
    font-size: rem(16);
  }
  .fs-md-18 {
    font-size: rem(18);
  }
  .fs-md-20 {
    font-size: rem(20);
  }
  .fs-md-22 {
    font-size: rem(22);
  }
  .fs-md-24 {
    font-size: rem(24);
  }
  .fs-md-30 {
    font-size: rem(30);
  }
  .fs-md-32 {
    font-size: rem(32);
  }
  .fs-md-40 {
    font-size: rem(40);
  }
  .fs-md-60 {
    font-size: rem(60);
  }
  .fs-md-100 {
    font-size: rem(100);
  }
  .fs-md-150 {
    font-size: rem(150);
  }
  .fs-md-180 {
    font-size: rem(180);
  }
}

@include media-breakpoint-up(lg) {
  // 992
  .fw-lg-400 {
    font-weight: 400;
  }
  .fw-lg-500 {
    font-weight: 500;
  }
  .fw-lg-700 {
    font-weight: 700;
  }

  .fs-lg-18 {
    font-size: rem(18);
  }
  .fs-lg-20 {
    font-size: rem(20);
  }
  .fs-lg-27 {
    font-size: rem(27);
  }
  .fs-lg-28 {
    font-size: rem(28);
  }
  .fs-lg-30 {
    font-size: rem(30);
  }
  .fs-lg-32 {
    font-size: rem(32);
  }
  .fs-lg-40 {
    font-size: rem(40);
  }
  .fs-lg-50 {
    font-size: rem(50);
  }
}

@include media-breakpoint-up(xl) {
  .fw-xl-300 {
    font-weight: 300;
  }

  .fs-xl-18 {
    font-size: rem(18);
  }
  .fs-xl-20 {
    font-size: rem(20);
  }
  .fs-xl-24 {
    font-size: rem(24);
  }
  .fs-xl-28 {
    font-size: rem(28);
  }
  .fs-xl-30 {
    font-size: rem(30);
  }
  .fs-xl-32 {
    font-size: rem(32);
  }
  .fs-xl-35 {
    font-size: rem(35);
  }
  .fs-xl-40 {
    font-size: rem(40);
  }
  .fs-xl-50 {
    font-size: rem(50);
  }
  .fs-xl-60 {
    font-size: rem(60);
  }
  .fs-xl-80 {
    font-size: rem(80);
  }
  .fs-xl-100 {
    font-size: rem(100);
  }
}

@include media-breakpoint-up(xxl) {
  .fs-xxl-20 {
    font-size: rem(20);
  }
}
