.more-info {
	margin-top: rem(-24);
	position: fixed;
	right: 0;
	top: 50%;
	z-index: 3;
	display: flex;
	&__c {
		padding-left: rem(2)
	}
	&__1 {
		background-color: $blue;
		border-top-left-radius: rem(24);
		border-bottom-left-radius: rem(24);
		height: rem(48);
		width: rem(65);
		&:before, &:after {
			background-color: #fff;
			content: "";
			display: block;
			position: absolute;
		}
		&:before {
			height: rem(24);
			width: rem(2);
			left: rem(24);
			top: rem(13)
		}
		&:after {
			height: rem(1);
			width: rem(24);
			left: rem(13);
			top: rem(24)
		}
	}
	&__2 {
		background-color: $blue;
		height: rem(48);
		line-height: rem(48);
		min-width: rem(230);
		position: absolute;
		right: rem(67);
		visibility: hidden;
	}
	&:hover {
		.more-info__1 {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			&:before { background-color: transparent }
		}
		.more-info__2 {
			visibility: visible;
		}
	}
	&:lang(eu) {
		.more-info__2 {
			min-width: rem(242);
		}
	}
}