.link-img-shadow {
  color: #fff;
  overflow: hidden;
  position: relative;
  &:before, &:after {
    content: "";
  }
  &:before {
    height: rem(150);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0,29,48,1) 0%, rgba(0,29,48,0) 100%);
    transition: background-color .6s ease-in-out;
    z-index: 1;
  }	
  &:after {
    padding-bottom: 100%;
  }
  img {
    transition: transform .6s ease-in-out;
  }
  &__content {
    padding: rem(16) rem(22);
    z-index: 1;
  }
  &:hover {
    img {
      transform: scale(1.2)
    }
  }
}

.list-request {
	counter-reset: section;
	&__item {
		h3 {
			border-bottom: rem(2) solid $blue;
			padding-bottom: rem(10);
			&:before {
				counter-increment: section;
				content: counter(section) ". "
			}
		}
	}
}

.list-contact {
	li {
		cursor: pointer;
		&.active{
			color: #fff;
			background-color: $tertiary;
		}
		&:not(.active) {
			p {
				opacity: .8;
			}
		}
		&:not(:last-child) {
			border-bottom: rem(1) solid #12263A;
		}
	}
}

