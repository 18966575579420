.news-modal {
    padding: 0 rem(11);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    z-index: 3;
    &__table {
        display: table;
        height: 100%;
    }
    &__table-cell {
        display: table-cell;
        vertical-align: middle;
    }
    &__content {
        padding: 0 rem(38);
    }
    @include media-breakpoint-up(sm) {
        max-width: rem(500);
        padding: 0;
    }
}

.news-modal__close {
    height: rem(42);
    position: absolute;
    right: rem(8);
    top: rem(8);
    width: rem(42);
}