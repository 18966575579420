.legal-texts {
  h2, h3 {
    color: $blue;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
  h2 {
    font-size: rem(16);
    font-weight: 700;
    margin-bottom: rem(32);
    text-align: center;
  }
  h3 {
    font-size: rem(16);
    margin-bottom: rem(26);
  }
  p + h3, ul + h3, ol + h3 {
    margin-top: rem(48)
  }
  @include media-breakpoint-up(lg) {
    h2 {
      font-size: rem(32);
      font-weight: 500;
      margin-bottom: rem(60);
    }
    h3 {
      font-size: rem(24);
      font-weight: 300;
    }
    p + h3, ul + h3, ol + h3 {
      margin-top: rem(76)
    }
  }
}